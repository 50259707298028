import { get, isEmpty, has } from "lodash";
import service from "@utils/apiService";
import config from "@utils/config";
import { includes } from "lodash";
const formatter = {
  id: (item: any) => {
    return get(item, "id") || "";
  },
  name: (item: any) => {
    return get(item, "attributes.name") || "";
  },
  email: (item: any) => {
    return get(item, "attributes.email") || "";
  },
  hashtag: (item: any) => {
    return get(item, "attributes.hashtag") || "";
  },
  title: (item: any) => {
    return get(item, "attributes.title") || "";
  },
  description: (item: any) => {
    return get(item, "attributes.description") || "";
  },
  buttons: (item: any) => {
    return get(item, "attributes.buttons") || "";
  },
  slug: (item: any) => {
    return get(item, "attributes.slug") || "";
  },

  price: (item: any) => {
    return get(item, "attributes.price") || "";
  },
  product_categories: (item: any) => {
    return (
      get(item, "attributes.product_categories.data") ||
      // get(item, "attributes.product_categories") ||
      []
    );
  },
  originalPrice: (item: any) => {
    return get(item, "attributes.originalPrice") || "";
  },
  logo: (item: any) => {
    return get(item, "attributes.logo") || "";
  },
  banner: (item: any) => {
    return get(item, "attributes.banner") || {};
  },
  thumbnail: (item: any) => {
    return get(item, "attributes.thumbnail") || {};
  },
  thumbnailBehind: (item: any) => {
    return get(item, "attributes.thumbnailBehind") || {};
  },
  general: (item: any) => {
    return get(item, "attributes.general") || {};
  },
  specification: (item: any) => {
    return get(item, "attributes.specification") || {};
  },
  content: (item: any) => {
    return get(item, "attributes.content") || [];
  },
  author: (item: any) => {
    return (
      get(item, "attributes.author.data") ||
      get(item, "attributes.author") ||
      []
    );
  },
  details: (item: any) => {
    return get(item, "attributes.details") || [];
  },
  createdAt: (item: any) => {
    return get(item, "attributes.createdAt") || "";
  },
  updatedAt: (item: any) => {
    return get(item, "attributes.updatedAt") || "";
  },
  publishedAt: (item: any) => {
    return get(item, "attributes.publishedAt") || "";
  },

  category: (item: any) => {
    return (
      get(item, "attributes.category.data") ||
      get(item, "attributes.category") ||
      []
    );
  },
  tags: (item: any) => {
    return (
      get(item, "attributes.tags.data") || get(item, "attributes.tags") || []
    );
  },
  services: (item: any) => {
    return get(item, "attributes.services") || {};
  },
  copyright: (item: any) => {
    return get(item, "attributes.copyright") || {};
  },
  icons: (item: any) => {
    return get(item, "attributes.icons") || {};
  },
  information: (item: any) => {
    return get(item, "attributes.information") || {};
  },
  children: (item: any) => {
    return (
      get(item, "attributes.children.data") ||
      get(item, "attributes.children") ||
      {}
    );
  },
  link: (item: any) => {
    let link = get(item, "attributes.link") || get(item, "attributes.href");
    if (config.ENV === "production" && includes(link, "localhost:3000")) {
      link = link.replace("localhost:3000", "annajp.com");
    }
    return link || "";
  },

  image: (item: any) => {
    return isEmpty(item)
      ? null
      : {
          src:
            service?.strapiUrl(
              get(item, "data.attributes.url") || get(item, "attributes.url")
            ) || "",
          alt:
            get(item, "data.attributes.alternativeText") ||
            get(item, "attributes.alternativeText") ||
            "",
          ext:
            get(item, "data.attributes.ext") ||
            get(item, "attributes.ext") ||
            "",
          mime:
            get(item, "data.attributes.mime") ||
            get(item, "attributes.mime") ||
            "",
          meta: {
            width:
              get(item, "data.attributes.width") ||
              get(item, "attributes.width") ||
              "",
            height:
              get(item, "data.attributes.height") ||
              get(item, "attributes.height") ||
              "",
            key: get(item, "data.id") || get(item, "attributes.hash") || "",
          },
        };
  },
  icon: (item: any) => {
    return isEmpty(item)
      ? null
      : {
          label: get(item, "label"),
          type: get(item, "iconType"),
          href: get(item, "iconLink") || "#",
        };
  },
  button: (item: any) => {
    return isEmpty(item)
      ? null
      : {
          label: get(item, "label", ""),
          link: get(item, "link") || "#",
          type: get(item, "type", ""),
        };
  },

  images: (item: any) => {
    return (
      (has(item, "attributes.images.data")
        ? get(item, "attributes.images.data")
        : get(item, "attributes.images")) || []
    );
  },
  metatags: (item: any) => {
    return get(item, "metatags") || get(item, "attributes.metatags") || {};
  },
  seo: (item: any) => {
    return (
      get(item, "seo") ||
      get(item, "attributes.seo") ||
      get(item, "data.attributes.seo") ||
      {}
    );
  },
  data: (item: any, model: string) => {
    return (
      get(item, `data.${model}.data`) ||
      get(item, `attributes.${model}.data`) ||
      get(item, `data.attributes.${model}.data`) ||
      null
    );
  },
};
export default formatter;

import { get } from "lodash";
// import helper, { dateFormatter, formatFullDateDisplay } from "@utils/helper";
const seoFormatter = {
  title: (item: any) => {
    return get(item, "metaTitle", "");
  },
  url: (item: any) => {
    return get(item, "metaUrl", "");
  },
  description: (item: any) => {
    return get(item, "metaDescription", "");
  },
  keywords: (item: any) => {
    return get(item, "keywords", "");
  },
  image: (item: any) => {
    return get(item, "metaImage.data.attributes.url", "");
  },
};
export default seoFormatter;

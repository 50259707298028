import icons from "./styleguide/icons";
import colors from "./styleguide/colors";
import fontSize from "./styleguide/fontSize";

const gstyles = {
  icons,
  colors,
  fontSize,
  sizes: [
    11, 12, 13, 14, 16, 18, 20, 21, 24, 28, 32, 36, 42, 48, 52, 58, 60, 64, 70,
    80, 96, 110,
  ],
};
export default gstyles;

import strapiGetter from "@services/StrapiService/getter";
import { get, isString, map, template } from "@utils/lodash";
import seoFormatter from "@utils/seoFormatter";
// import { formatDateDisplay } from "./dateFormatter";
const helper = {
  interpolateContent: (content: any, attributes: any) => {
    return template(content, {
      interpolate: /{{([\s\S]+?)}}/g,
    })(attributes);
  },
  replaceAll: (str: string = "", find: string, replace: string) => {
    if (!isString(str)) return "";
    return str.split(find).join(replace);
  },
  isInternal: (to: string) => {
    return /^\/(?!\/)/.test(to);
  },
  isExternal: (val: string) => {
    return `${val}`.substring(0, 4) === "http";
  },
  copy: (val: string) => {
    navigator.clipboard.writeText(val);
  },
  formatMetatags: (data: any, metas?: any) => {
    const pageData = strapiGetter.seo(data);
    return {
      title: seoFormatter.title(pageData) || "Eh Phuong",
      description:
        seoFormatter.description(pageData) ||
        "Ê Phương - chia sẻ kinh nghiệm lập trình trên hành trình phát triển bản thân.",
      keywords: seoFormatter.keywords(pageData),
      images: [seoFormatter.image(pageData)] || [],
      url: seoFormatter.url(pageData) || "",
      openGraph: {
        title: seoFormatter.title(pageData) || "Eh Phuong",
        description:
          seoFormatter.description(pageData) ||
          "Ê Phương - chia sẻ kinh nghiệm lập trình trên hành trình phát triển bản thân.",
        url: seoFormatter.url(pageData) || "",
        images: [seoFormatter.image(pageData)] || [],
      },
      twitter: {
        title: seoFormatter.title(pageData) || "Eh Phuong",
        description:
          seoFormatter.description(pageData) ||
          "Ê Phương - chia sẻ kinh nghiệm lập trình trên hành trình phát triển bản thân.",
        images: [seoFormatter.image(pageData)] || [],
      },
      other: {
        image: seoFormatter.image(pageData),
      },
      icons: {
        icon: ["/favicon.ico?v=2"],
        apple: ["/apple-touch-icon.png?v=2"],
        shortcut: ["/apple-touch-icon.png?v=2"],
      },
      manifest: "/site.webmanifest",
      ...metas,
    };
  },
  formatArticle: (item: any) => {
    return {
      title: strapiGetter.title(item),
      description: strapiGetter.description(item),
      slug: strapiGetter.slug(item),
      image: strapiGetter.image(get(item, "attributes.image")),
      content: strapiGetter.content(item),
      tags: strapiGetter.tags(item),
      createdAt: strapiGetter.createdAt(item),
      updatedAt: strapiGetter.updatedAt(item),
      publishedAt: strapiGetter.publishedAt(item),
      category: helper.formatCategory(strapiGetter.category(item)),
      details: strapiGetter.details(item),
    };
  },
  formatCategory: (item: any) => {
    return {
      name: strapiGetter.name(item),
      slug: strapiGetter.slug(item),
    };
  },
  formatTag: (item: any) => {
    return {
      name: strapiGetter.name(item),
      hashtag: strapiGetter.hashtag(item),
    };
  },
  formatAuthor: (item: any) => {
    return {
      name: strapiGetter.name(item),
      email: strapiGetter.email(item),
      image: strapiGetter.image(get(item, "attributes.image")),
    };
  },
  formatProduct: (item: any) => {
    return {
      name: strapiGetter.name(item),
      thumbnail: strapiGetter.image(strapiGetter.thumbnail(item)),
      thumbnailBehind: strapiGetter.image(strapiGetter.thumbnailBehind(item)),
      images: map(strapiGetter.images(item), (img: any) =>
        strapiGetter.image(img)
      ),
      price: strapiGetter.price(item),
      originalPrice: strapiGetter.originalPrice(item),
      slug: strapiGetter.slug(item),
      description: strapiGetter.description(item),
      general: strapiGetter.general(item),
      specification: strapiGetter.specification(item),
      buttons: map(strapiGetter.buttons(item), strapiGetter.button),
      product_categories: map(
        strapiGetter.product_categories(item),
        helper.formatProductCategory
      ),
      createdAt: strapiGetter.createdAt(item),
    };
  },

  formatProductCategory: (item: any) => {
    return {
      name: strapiGetter.name(item),
      slug: strapiGetter.slug(item),
    };
  },
};

export default helper;
